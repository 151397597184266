// control details for action plan
const CONTROL_DETAILS = `
    id
    topic
    controlNumber
    category
    subCategory
    description
    keyControl
    implementationGuideAdditionalInformation
    implementationGuide
    executionGuide
    controlExplanation
    `
// control assessment details for action plan
const CONTROL_ASSESSMENT = `
        id
        dateNextAssessment
        executionDate
        control {
            ${CONTROL_DETAILS}
        }
    `

// action plan data query
const ACTION_PLAN_DETAILS = `
    id
    refId
    alertColor{
        color
        info
        infoKey
    }
    validationAlertColor{
        color
        info
        infoKey
    }
    state
    actionType
    description
    shortDescription
    state
    documentationUrl
    startDate
    endDate
    approverDate
    ownerRemark
    approverRemark
    approver {
        id
        firstName
        lastName
        username
    }
    owner {
        id
        firstName
        lastName
        username
    }
    submittedBy {
        id
        username
    }
    controlAssessment{
        ${CONTROL_ASSESSMENT}
    }
    `

// action plan approval return query
const ACTION_PLAN_APPROVAL_DETAILS = `
    id
    state
    approver {
        id
        firstName
        lastName
        username
    }
    approverDate
    approverRemark
    `
// get action plans list data
const ACTION_PLANS_LIST = `
        id
        order
        refId
        alertColor{
            color
            info
            infoKey
        }
        validationAlertColor{
            color
            info
            infoKey
        }
        description
        state
        startDate
        endDate
        owner {
            id
            firstName
            lastName
            username
        }
        controlAssessment {
            id
            control {
                id
                controlNumber
                category
                subCategory
                description
            }
        }
    `
export default {
    ACTION_PLAN_DETAILS,
    CONTROL_ASSESSMENT,
    ACTION_PLAN_APPROVAL_DETAILS,
    ACTION_PLANS_LIST,
}
